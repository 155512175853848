import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Section from '../components/section';
import ProjectGrid from '../components/projectGrid';
import Title from '../components/title';

const Projects = ({ data }) => (
  <Layout>
    <Section>
      <Title pageTitle={data.projectsJson.title} />
      <div
        dangerouslySetInnerHTML={{
          __html: data.projectsJson.content.childMarkdownRemark.html,
        }}
      />
      <ProjectGrid items={data.projectsJson.projects} />
    </Section>
  </Layout>
);

Projects.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Projects;

export const query = graphql`
  query ProjectsQuery {
    projectsJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      projects {
        artist
        time
        genre
        description
        soundcloud
        youtube
        facebook
        spotify
        bandcamp
      }
    }
  }
`;
