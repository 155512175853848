import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';
import Soundcloud from '../../images/icon-soundcloud.png';
import Youtube from '../../images/icon-youtube.png';
import Facebook from '../../images/icon-facebook.png';
import Spotify from '../../images/icon-spotify.png';
import Bandcamp from '../../images/icon-bandcamp.png';

const Container = styled.div`
  ${MEDIA.MIN_TABLET`
    display: grid;
    grid-template-columns: 2.5fr 1fr 2fr 2fr 2fr;
    grid-auto-rows: minmax(3.375rem, auto);
    grid-gap: 1rem;
  `};
`;
const Cell = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0;

  ${MEDIA.MIN_TABLET`
    display: grid;
    padding: 0.5rem 0;
  `};
`;
const Header = styled.div`
  flex-basis: 40%;
  span {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #eaca27;
  }

  ${MEDIA.MIN_TABLET`
    display: none;
  `};
`;
const Content = styled.div`
  flex-basis: 60%;
  p,
  a {
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    letter-spacing: 0;
    color: #f1f6f7;
    margin: 0;
  }
`;

const Links = styled.div`
  display: flex;
`;

const SocialLink = styled.a`
  margin: 0 0.25rem !important;
`;

const EmptySocialLink = styled.span`
  display: none;

  ${MEDIA.MIN_TABLET`
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0 0.25rem !important;
  `};
`;

const Item = ({
  artist,
  time,
  genre,
  description,
  soundcloud,
  youtube,
  facebook,
  spotify,
  bandcamp,
}) => {
  return (
    <Container>
      <Cell>
        <Header>
          <span>Project</span>
        </Header>
        <Content>
          <p>{artist}</p>
        </Content>
      </Cell>
      <Cell>
        <Header>
          <span>Year</span>
        </Header>
        <Content>
          <p>{time}</p>
        </Content>
      </Cell>
      <Cell>
        <Header>
          <span>Genre</span>
        </Header>
        <Content>
          <p>{genre}</p>
        </Content>
      </Cell>
      <Cell>
        <Header>
          <span>Description</span>
        </Header>
        <Content>
          <p>{description}</p>
        </Content>
      </Cell>
      <Cell>
        <Header>
          <span>Links</span>
        </Header>
        <Content>
          <Links>
            {facebook ? (
              <SocialLink href={facebook}>
                <img src={Facebook} alt="Facebook" />
              </SocialLink>
            ) : (
              <EmptySocialLink />
            )}
            {youtube ? (
              <SocialLink href={youtube}>
                <img src={Youtube} alt="Youtube" />
              </SocialLink>
            ) : (
              <EmptySocialLink />
            )}
            {soundcloud ? (
              <SocialLink href={soundcloud}>
                <img src={Soundcloud} alt="Soundcloud" />
              </SocialLink>
            ) : (
              <EmptySocialLink />
            )}

            {spotify ? (
              <SocialLink href={spotify}>
                <img src={Spotify} alt="Spotify" />
              </SocialLink>
            ) : (
              <EmptySocialLink />
            )}
            {bandcamp ? (
              <SocialLink href={bandcamp}>
                <img src={Bandcamp} alt="Bandcamp" />
              </SocialLink>
            ) : (
              <EmptySocialLink />
            )}
          </Links>
        </Content>
      </Cell>
    </Container>
  );
};

Item.propTypes = {
  artist: PropTypes.string,
  time: PropTypes.string,
  genre: PropTypes.string,
  description: PropTypes.string,
  soundcloud: PropTypes.string,
  youtube: PropTypes.string,
  facebook: PropTypes.string,
  spotify: PropTypes.string,
  bandcamp: PropTypes.string,
};

export default Item;
