import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DefaultTitle = styled.h1``;

const YellowTitle = styled.h1`
  color: #eaca27;
`;

const Title = ({ pageTitle, featured }) => {
  return featured ? (
    <YellowTitle>{pageTitle}</YellowTitle>
  ) : (
    <DefaultTitle>{pageTitle}</DefaultTitle>
  );
};

Title.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  featured: PropTypes.bool,
};

export default Title;
