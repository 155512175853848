import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MEDIA from '../../helpers/mediaTemplates';
import Item from './item';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const ListHeader = styled.div`
  display: none;

  ${MEDIA.MIN_TABLET`
    display: grid;
    grid-template-columns: 2.5fr 1fr 2fr 2fr 2fr;
    grid-template-rows: auto;
    grid-gap: 1rem;
    margin-bottom: 1rem;
  `};
`;
const Header = styled.div`
  span {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 0.8125rem;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #eaca27;
  }
`;

const ItemList = styled.div`
  display: grid;
  grid-gap: 2rem;

  > *:nth-child(2n) {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      left: -2.5rem;
      right: -2.5rem;
      z-index: -1;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  ${MEDIA.MIN_TABLET`
    grid-gap: 1rem;
  `};
`;

const ProjectGrid = ({ items }) => (
  <Container>
    <ListHeader>
      <Header>
        <span>Project</span>
      </Header>
      <Header>
        <span>Year</span>
      </Header>
      <Header>
        <span>Genre</span>
      </Header>
      <Header>
        <span>Description</span>
      </Header>
      <Header>
        <span>Links</span>
      </Header>
    </ListHeader>
    <ItemList>
      {items.map((item, i) => (
        <Item {...item} key={i} />
      ))}
    </ItemList>
  </Container>
);

ProjectGrid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProjectGrid;
